<template>
 <div>
   <NewappLFW v-if="minitData.template_id==1"></NewappLFW>
   <NewappICC v-else-if="minitData.template_id==5"></NewappICC>
    <NewappPKL v-else-if="minitData.template_id==2"></NewappPKL>
    
   <NewappILT v-else-if="minitData.template_id==7"></NewappILT>
    
   <NewappPklWI v-else-if="minitData.template_id==6"></NewappPklWI>

   
   
 </div>
</template>

<script>

import NewappPKL from "../components/Template/PKL/NewappPKL.vue"
import NewappLFW from "../components/Template/LFW/NewappLFW.vue"
import NewappICC from "../components/Template/ICC/NewappICC.vue"
import NewappILT from "../components/Template/ILT/NewappICC.vue"
import NewappPklWI from "../components/Template/PKLWI/NewappPklWI.vue"


export default {
  components: { NewappPKL, NewappLFW,NewappICC,NewappPklWI,NewappILT },
  data: () => ({
    minitData: "",
  }),
 
  async mounted() {
    
    this.isLoading = true;
    const mtemp = await this.$axios.get(
      "/media/onload/" +
        this.$store.state.eventUrl +
        "/" +
        this.$store.state.venueUrl
    );
    this.minitData = await mtemp.data.result;
    console.log(this.minitData);
  },

};
</script>
